import React, { useEffect, useState } from 'react';
import {
    Grid,
    NoSsr,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Layout, TopNavigation, VideoList, FullScreenTextLoader } from 'components';
import serviceHelper from 'helpers/serviceHelper';
import { parseImage } from 'helpers/string'

export default function Films({ location }) {
    const classes = useStyles();
    const [content, setContent] = useState();
    const [imgLoaded, setImgLoaded] = useState(false);

    useEffect(() => {
        let timer;
        if (!imgLoaded && content && content.playlist && content.playlist.length) {
            const loadImage = image => {
                return new Promise((resolve, reject) => {
                    const loadImg = new Image()
                    loadImg.src = image
                    loadImg.onload = () => resolve(image)

                    loadImg.onerror = err => reject(err)
                })
            }

            timer = setTimeout(() => {
                if (!imgLoaded) {
                    setImgLoaded(true);
                }
            }, 25000)

            Promise.all(content.playlist.map(item => item.smallFeaturedImage && loadImage(parseImage(item.smallFeaturedImage))))
                .then(() => setImgLoaded(true))
                .catch(err => console.log("Failed to load images", err))
        }
        return () => {
            clearTimeout(timer);
        }
    }, [content])

    const seo = {
      title: "DOCUMENTARY+",
      description: `Films page - Home to the world’s best documentaries -- from Academy 
    Award winning classics and box office hits to festival darlings and cult favorites. 
    Nonfiction storytelling at its best, curated by the best.`,
    };

    useEffect(() => {
        getPlaylists();
    }, [])

    const getPlaylists = async () => {
        const playlistData = await serviceHelper.getPlaylist('o8VaZNFs');
        if (!playlistData.isError) {
            const data = playlistData.data || [];
            setContent(data)
        }
    }

    const renderVideos = () => {
        // if (!(content && content.length)) {
        //     return (
        //         <div style={{ height: '75vh' }}>
        //             <FullScreenTextLoader open={!(content && content.length)} />
        //         </div>
        //     )
        // }
        return (
            <React.Fragment>
                <FullScreenTextLoader open={!(content && imgLoaded)} />
                <VideoList content={content} />
            </React.Fragment>)
    }

    return (
        <Layout location={location} seo={seo}>
            <TopNavigation location={location} />
            <section id="header" className={classes.section} >
                <Grid container spacing={1}>
                    {renderVideos()}
                </Grid>
                <NoSsr>
                </NoSsr>
            </section>
        </Layout>
    );
}


const useStyles = makeStyles((theme) => ({
    section: {
        margin: '0 24px',
        marginTop: '16px',
        [theme.breakpoints.between(0, 600)]: {
            margin: '0 16px'
        },
    },
}));
